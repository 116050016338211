import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { Col, Button, List, message, Popconfirm, Row, Tooltip } from 'antd'
import { GET_PRODUCT_ARCHIVED_VARIANTS } from '../ProductQueries'
import { RESTORE_VARIANT } from '../VariantGroups/VariantQueries'
import { ReloadOutlined } from '@ant-design/icons'
import Loading from '../../../Utils/Loading'
import { openArchiveWarning } from './../../../Utils/ArchiveWarning'
import VariantNameAndAvatar from 'components/Widgets/VariantNameAndAvatar'

interface Option {
  name: string
  values: string
}

interface ProductVariant {
  id: string
  archived_at: string
  default_variant: boolean
  image: string
  inserted_at: string
  limit: number
  name: string
  options: Array<object>
  price: number
  published: boolean
  quantity: number
  restrictions: {
    id: string
    alcoholic: boolean
  }
  sku: string
  vat: number
  store_variants: {
    store_id?: string
    preorder_store_id?: string
    published_at: string | null
  }[]
  product: {
    publish_to_stores: boolean
  }
  pricings: {
    id: string
    variant_id: string
    fulfillment_type: string
    price: number
    price_with_vat: number
    vat: number
  }[]
}

interface ArchivedProductVariantsListProps {
  productId: string
}

const ArchivedProductVariantsList = ({
  productId
}: ArchivedProductVariantsListProps) => {
  const client = useApolloClient()
  const [baseName, setBaseName] = useState<string>('')
  const [productVariants, setProductVariants] = useState<Array<ProductVariant>>(
    []
  )
  const [restoreVariant, { loading: restoringVariant }] =
    useMutation(RESTORE_VARIANT)
  const { data: productArchivedVariants, loading: fetchingArchivedVariants } =
    useQuery(GET_PRODUCT_ARCHIVED_VARIANTS, {
      variables: {
        productId
      }
    })

  useEffect(() => {
    if (productArchivedVariants) {
      const { archived_variants } = productArchivedVariants

      if (archived_variants.length) {
        setBaseName(archived_variants[0]?.product?.name)
        setProductVariants(archived_variants)
      }
    }
  }, [productArchivedVariants])

  const handleRestore = (
    productVariantId: string,
    productVariantName: string
  ) => {
    message.destroy()
    message.loading('Restoring product variant..', 1)
    restoreVariant({
      variables: {
        productId,
        variantId: productVariantId
      }
    })
      .then((result) => {
        client.resetStore()
        message.destroy()
        openArchiveWarning({
          itemId: productVariantId,
          itemName: productVariantName,
          description: 'product variant has been restored',
          placement: 'topLeft'
        })
      })
      .catch((error: Error) => {
        message.destroy()
        openArchiveWarning({
          itemId: productVariantId,
          itemName: productVariantName,
          description: `Product variant failed to restore due to ${error.message}`,
          placement: 'topLeft'
        })
      })
  }

  const renderProductVariantRow = (variant: ProductVariant) => {
    const { name, id, options } = variant
    const variantOptions = options.map(({ value }: Option) => value)

    return (
      <List.Item
        actions={[
          <Tooltip placement='top' title='Restore'>
            <Popconfirm
              title='Are you sure you want to restore this variant?'
              okText='Yes'
              cancelButtonProps={{ type: 'ghost' }}
              cancelText='No'
              onConfirm={() => handleRestore(id, name)}
            >
              <Button
                data-testid={`restore-product-variant-${id}`}
                size='small'
                disabled={restoringVariant}
                loading={restoringVariant}
                icon={<ReloadOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        ]}
      >
        <Row className='-product-list _w-100' align='middle' gutter={[8, 0]}>
          <Col span={24}>
            <VariantNameAndAvatar
              id={id}
              name={baseName}
              testId={'archived-variants-list-item'}
              options={variantOptions}
            />
          </Col>
        </Row>
      </List.Item>
    )
  }

  return (
    <>
      {fetchingArchivedVariants && <Loading />}
      {!fetchingArchivedVariants && productVariants && (
        <List
          bordered
          dataSource={productVariants.filter((pv) => !!pv.options)}
          renderItem={renderProductVariantRow}
          pagination={{
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 100
          }}
          className='mb-1rem'
        />
      )}
    </>
  )
}

export default ArchivedProductVariantsList
