import { NormalizedVariant, ProductVariant, Option } from './types'
import snakeCase from 'lodash/snakeCase'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'

/*
 *  this filters an array of variants with the name of an option
 *  with options (ala variant group names) as Size, Color etc. etc.
 *
 */
const getVariantsByOption = (
  variants: ProductVariant[],
  optionName: string,
  optionValues: string | string[]
) => {
  return (variants || []).filter((variant) => {
    return !!(variant.options || []).filter(
      (option) =>
        snakeCase(option.name) === snakeCase(optionName) &&
        optionValues.includes(option.value)
    ).length
  })
}

/*
 * this groups variants by variant group name
 * with variant group name as Size, Color etc.
 * */
const GroupVariantsByGroupName = (
  options: Option[],
  variants: ProductVariant[]
) => {
  return options.reduce(
    (map: Record<string, ProductVariant[]>, { name, values }) => {
      map[snakeCase(name)] = getVariantsByOption(variants, name, values)
      return map
    },
    {}
  )
}

const GroupVariantsByOptionName = (
  options: string[],
  variantGroupName: string,
  variants: ProductVariant[]
) => {
  return options.reduce((map: Record<string, ProductVariant[]>, value) => {
    map[snakeCase(value)] = getVariantsByOption(
      variants,
      variantGroupName,
      value
    )
    return map
  }, {})
}

const SymbolizeVariantGroupNames = (options: Option[]) => {
  return options.reduce((map: Record<string, string>, { name }) => {
    map[snakeCase(name)] = name
    return map
  }, {})
}

const SymbolizeVariantGroupOptions = (options: Option[]) => {
  return options.reduce(
    (map: Record<string, Array<string>>, { name, values }) => {
      map[snakeCase(name)] = values.map((value: string) => value.toLowerCase())
      return map
    },
    {}
  )
}

const isValidFloat = (val: number | null | string) => {
  const parsed = parseFloat(val)
  return !isNaN(parsed)
}

const NormalizeProductVariant = (variants: ProductVariant[]) => {
  return variants.map(
    ({
      id,
      archived_at,
      default_variant,
      image,
      inserted_at,
      updated_at,
      limit,
      name,
      options,
      price,
      published,
      quantity,
      restrictions,
      sku,
      source_id,
      vat,
      calorie_data
    }: ProductVariant) => ({
      id,
      archived_at,
      default_variant,
      image,
      inserted_at,
      updated_at,
      limit,
      name,
      options,
      price,
      published,
      quantity,
      restrictions,
      sku,
      source_id,
      vat,
      calorie_data
    })
  )
}

const isCaloriesValueValid = (value: number | null) => {
  if (isEmpty(value)) return true //allow empty values
  if (parseFloat(value) === 0) return true
  return parseFloat(value) > 0
}

const isLimitValueValid = (value: number | null) => {
  if (parseFloat(value) === 0) return true
  return parseFloat(value) > 0
}

/*
 * this sorts by variant name, then by option's name then the value
 *
 */
const SortVariantsByOptions = (variants: NormalizedVariant) =>
  sortBy(variants, ['name', ['option', ['name', 'value']]])

export {
  GroupVariantsByGroupName,
  GroupVariantsByOptionName,
  isValidFloat,
  NormalizeProductVariant,
  SortVariantsByOptions,
  isCaloriesValueValid,
  isLimitValueValid,
  SymbolizeVariantGroupNames,
  SymbolizeVariantGroupOptions
}
