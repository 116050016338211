import React, { useState } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { Store } from 'antd/lib/form/interface'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Switch from 'antd/lib/switch'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import PageHeader from 'antd/lib/page-header'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useMutation, useApolloClient, ApolloClient } from '@apollo/client'
import {
  QUERY_EXISTING_LOCATION_SLUG_FOR_MERCHANT,
  QUERY_EXISTING_LOCATION_NAME_FOR_MERCHANT,
  QUERY_EXISTING_OLD_STORE_SLUG
} from '../LocationQueries'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import { UPDATE_STORE, STORE_SLUG_CHANGE_LOG, TOGGLE_STORE } from './../actions'
import { LocationSettingProps } from './../Settings'
import AddressInput from './../LocationForm/AddressInput'
import AddressForm from './Forms/AddressForm'
import { slugify } from '@slerp/helpers'
import {
  UKPhoneValidatorRule,
  noAddressSelectedRule
} from '../LocationForm/rules'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'
import { QUERY_MERCHANT_IS_SEO_ENABLED } from 'components/Merchants/MerchantQueries'
import SeoLearnMore from 'components/Utils/SeoLearnMore'
const { useForm } = Form

const DUMMY_ADDRESS_LINE_2 = '166 Shoreditch High Street'

const UniqueSlugValidatorRule = (
  client: ApolloClient<any>,
  merchantId: string,
  storeSlug: string
) => ({
  async validator(_: any, value: string) {
    if (!value) {
      return
    }

    if (storeSlug === value) {
      return
    }

    const {
      data: { stores }
    } = await client.query({
      query: QUERY_EXISTING_LOCATION_SLUG_FOR_MERCHANT,
      variables: {
        merchantId: merchantId,
        storeSlug: value
      }
    })

    const {
      data: { slug_logs }
    } = await client.query({
      query: QUERY_EXISTING_OLD_STORE_SLUG,
      variables: {
        merchantId: merchantId,
        storeSlug: value
      }
    })

    if (stores.length || slug_logs.length) {
      return Promise.reject(
        'This Location URL has already been used for another Location. Please try another one.'
      )
    }

    return Promise.resolve()
  }
})

const UniqueLocationNameValidatorRule = (
  client: ApolloClient<any>,
  merchantId: string,
  storeName: string
) => ({
  async validator(_: any, value: string) {
    if (!value) {
      return
    }

    if (storeName === value) {
      return
    }

    const {
      data: { stores }
    } = await client.query({
      query: QUERY_EXISTING_LOCATION_NAME_FOR_MERCHANT,
      variables: {
        merchantId: merchantId,
        storeName: value
      }
    })

    if (stores.length) {
      return Promise.reject(
        'This location name is already in use, please use another name'
      )
    }

    return Promise.resolve()
  }
})

interface addressCache {
  line1: string
  line2: string
  city: string
  lat: number
  lng: number
  postalCode: string
}

interface AddressHash {
  validateTrigger: boolean
  noSuggestionSelected: boolean
  formattedAddress: string
  line1: string
  line2: string
  city: string
  country: string
  postalCode: string
  lat: number
  lng: number
}

const StoreDetails = (props: LocationSettingProps) => {
  const { store, merchant } = props
  const { user } = useSession()
  const [form] = useForm()
  const [updateStore, { loading }] = useMutation(UPDATE_STORE, {
    fetchPolicy: 'no-cache'
  })
  const [logSlugChange] = useMutation(STORE_SLUG_CHANGE_LOG, {
    fetchPolicy: 'no-cache'
  })
  const [openMetaModal, setOpenMetaModal] = useState<boolean>(false)
  const [isSeoEnabled, setIsSeoEnabled] = useState<boolean>(false)
  const [descriptionRemainingChars, setDescriptionRemainingChars] =
    useState<number>(store.description ? 300 - store.description?.length : 300)
  const [seoDescriptionRemainingChars, setSeoDescriptionRemainingChars] =
    useState<number>(
      store.seo_description ? 160 - store.seo_description?.length : 160
    )

  const canManage = isManager(user) || isAdmin(user)
  const client = useApolloClient()

  const { refetch } = useQuery(QUERY_MERCHANT_IS_SEO_ENABLED, {
    variables: {
      id: merchant.id
    },
    onCompleted: (data) => {
      const { is_seo_enabled } = data.merchant
      setIsSeoEnabled(is_seo_enabled)
    }
  })

  const [toggleStore] = useMutation(TOGGLE_STORE)

  const stringifyAddress = (address: addressCache) => {
    const addressObj = {
      address: address.line1
        ? `${address.line1} ${address.line2}`
        : address.line2,
      city: address.city,
      lat: address.lat,
      lng: address.lng,
      zip: address.postalCode
    }

    return JSON.stringify(addressObj)
  }

  const onFinish = (values: Store) => {
    const {
      storeName,
      slug,
      email,
      courierInstructions,
      contactNumber,
      address,
      is_archived,
      address_line_1,
      address_line_2,
      address_postcode,
      address_country,
      address_city,
      address_lat,
      address_lng,
      description,
      seo_description
    } = values
    const line_1 = isEmpty(address_line_1) ? address_line_2 : address_line_1

    const newAddress = {
      city: address_city,
      country: address_country,
      formattedAddress: address.formatted_address,
      lat: address_lat,
      line1: line_1,
      line2: address_line_2,
      lng: address_lng,
      noSuggestionSelected: address.noSuggestionSelected,
      postalCode: address_postcode,
      validationTrigger: address.validationTrigger
    }

    const addressCache =
      DUMMY_ADDRESS_LINE_2 === store.address.line_2
        ? stringifyAddress(newAddress)
        : stringifyAddress({
            line1: line_1,
            line2: address_line_2,
            city: address_city,
            postalCode: address_postcode || '',
            lat: address_lat,
            lng: address_lng
          })

    form.validateFields().then(() => {
      message.loading('Updating... Please wait.')
      updateStore({
        variables: {
          store_id: store.id,
          name: storeName,
          slug: slug,
          email: email,
          pickup_notes: courierInstructions,
          line_1: newAddress.line1,
          line_2: newAddress.line2,
          city: newAddress.city,
          country: newAddress.country,
          zip: newAddress.postalCode.toUpperCase(),
          description,
          seo_description,
          geom: {
            type: 'Point',
            crs: {
              type: 'name',
              properties: {
                name: 'urn:ogc:def:crs:EPSG::4326'
              }
            },
            coordinates: [newAddress.lat, newAddress.lng]
          },
          contact_num: `+44${contactNumber}`,
          archived_at: is_archived ? moment().utc(true).toISOString() : null,
          address_cache: addressCache,
          address_is_new_version: true
        }
      })
        .then(async (result) => {
          message.destroy()
          client.resetStore()
          message.success('Merchant Updated', 1)

          if (slug !== store.slug) {
            await logSlugChange({
              variables: {
                storeId: store.id,
                oldSlug: store.slug,
                newSlug: slug
              }
            })
          }

          const pathname = window.location.pathname
          window.location.pathname = pathname.replace(store.slug, slug)
        })
        .catch((error) => {
          message.destroy()
          message.error(
            <span>
              <strong>
                There has been an issue with the address that you entered.
              </strong>
              <br />
              <div className='_pt-4'>
                Try typing your company name and please ensure that the
                <br />
                address is registered with your business listing on Google
                Business.
              </div>
            </span>,
            10
          )
        })
    })
  }

  const toggleStoreHandler = (isOpen: boolean) => {
    toggleStore({
      variables: {
        store_id: store.id,
        is_open: isOpen
      }
    })
      .then(() => {
        message.success(`Your store is now ${isOpen ? 'open' : 'closed'}`, 5)
      })
      .catch((error) =>
        error.message(
          `I was not able to ${
            isOpen ? 'open' : 'close'
          } your store due to: ${error}`,
          10
        )
      )
  }

  const createStoreSlug = (storeName: string) => {
    const formatted_store_name = slugify(storeName)
    const formatted_merchant_name = slugify(merchant.name)
    return `${formatted_merchant_name}-${formatted_store_name}`
  }

  const initialValues = {
    storeName: store.name,
    email: store.email,
    slug: store.slug,
    contactNumber: store.address.contact_num.substring(3),
    address: {
      formattedAddress: compact([
        store.address.line_1,
        store.address.line_2,
        store.address.city,
        store.address.zip
      ]).join(', ')
    },
    courierInstructions: store.pickup_notes,
    is_archived: store.archived_at,
    address_line_1: store?.address?.line_1 || '',
    address_line_2: store?.address?.line_2 || '',
    address_city: store?.address?.city || '',
    address_postcode: store?.address?.zip || '',
    address_country: store?.address?.country || '',
    address_lat: (store?.address?.geom?.coordinates || [])[0],
    address_lng: (store?.address?.geom?.coordinates || [])[1],
    is_open: store.is_open,
    description: store.description ?? '',
    seo_description: store.seo_description ?? ''
  }

  const updateAddressFields = (addressHash: AddressHash) => {
    form.setFieldsValue({
      address_line_1: addressHash.line1,
      address_line_2: addressHash.line2,
      address_city: addressHash.city,
      address_postcode: addressHash.postalCode,
      address_country: addressHash.country,
      address_lat: addressHash.lat,
      address_lng: addressHash.lng
    })
  }

  return (
    <>
      <SeoLearnMore
        openMetaModal={openMetaModal}
        setOpenMetaModal={setOpenMetaModal}
      />
      <Form
        data-testid='general-settings-form'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row align='top' justify='space-between' className='_mb-0'>
          <PageHeader title='Store Details' className='settings-title' />
          <Row align='middle' className='_mb-0'>
            <Col className='_mb-0 _mr-8'>
              <Form.Item shouldUpdate className='_mb-0'>
                {() => {
                  return `Your store is ${
                    form.getFieldValue('is_open') ? 'open' : 'closed'
                  }`
                }}
              </Form.Item>
            </Col>
            <Form.Item
              data-testid='store-status-switch'
              name='is_open'
              valuePropName='checked'
              className='_mb-0'
            >
              <Switch
                loading={loading}
                disabled={!canManage}
                onChange={toggleStoreHandler}
              />
            </Form.Item>
          </Row>
        </Row>

        <Form.Item
          name='storeName'
          label='Name'
          rules={[
            {
              required: true,
              message: 'Store name is required'
            },
            UniqueLocationNameValidatorRule(client, merchant.id, store.name)
          ]}
          required
        >
          <Input
            placeholder='Store name'
            disabled={!canManage}
            onChange={(e) =>
              form.setFieldsValue({ slug: createStoreSlug(e.target.value) })
            }
          />
        </Form.Item>

        <Row className='-category-row'>
          <Col>
            Store Description
            <Tooltip
              title={
                <span>
                  Category descriptions can help customers better understand
                  what is on offer. They should be informative and persuasive.
                  <br />
                  <br />
                  They can also help create more unique content on your page,
                  which boosts the SEO ranking of your shop page.
                </span>
              }
            >
              <QuestionCircleOutlined className='_ml-8 _mr-8' />
            </Tooltip>
          </Col>
        </Row>
        <Row className='_mb-0'>
          <Col span={24}>
            <Form.Item
              name='description'
              validateTrigger='onBlur'
              className='category-description'
              rules={[
                {
                  validator: (_, val) => {
                    if (!val) {
                      return Promise.resolve()
                    }

                    if (val.match(/<\/?[^>]+(>|$)/g)?.length) {
                      return Promise.reject('HTML tags are not allowed')
                    }

                    return Promise.resolve()
                  }
                }
              ]}
            >
              <Input.TextArea
                placeholder='Enter description here'
                data-testid='category-form-description'
                autoSize={{ minRows: 2 }}
                maxLength={300}
                onChange={(e) =>
                  setDescriptionRemainingChars(300 - e.target.value.length)
                }
              />
            </Form.Item>
            {/* Had to use px now for font sizes, which is not ideal but is what our designer wants. */}
            <Row
              style={{
                fontSize: '11px',
                position: 'absolute',
                bottom: '-1.2rem',
                right: 0
              }}
            >
              Remaining characters {descriptionRemainingChars}
            </Row>
          </Col>
        </Row>

        {isSeoEnabled && (
          <Row gutter={[8, 8]} className='_mb-0'>
            <Col span={24}>
              <Row>
                <Col>SEO description:</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name='seo_description'>
                    <Input.TextArea
                      rows={2}
                      autoSize={{ minRows: 2 }}
                      placeholder='Enter SEO description'
                      data-testid='store-form-seo-description'
                      maxLength={160}
                      onChange={(e) =>
                        setSeoDescriptionRemainingChars(
                          160 - e.target.value.length
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  fontSize: '11px',
                  position: 'absolute',
                  bottom: '25px',
                  right: 0
                }}
              >
                Remaining characters {seoDescriptionRemainingChars}
              </Row>
              <Row justify='end' gutter={[8, 8]} className='_mb-0'>
                <Col>
                  <Button
                    style={{
                      color: '#77778E',
                      background: '#FAFAFB',
                      borderStyle: 'none'
                    }}
                    onClick={() => setOpenMetaModal(true)}
                  >
                    LEARN MORE
                  </Button>
                </Col>
                <Col>
                  {/* <Button type='ghost'>
                        <BsStars style={{ marginRight: '8px' }} />
                        GENERATE
                      </Button> */}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              message: 'Email is required'
            },
            {
              type: 'email',
              message: 'Please input a valid email'
            }
          ]}
          required
        >
          <Input placeholder='Store email' disabled={!canManage} />
        </Form.Item>

        <Form.Item
          name='address'
          label='Address'
          rules={noAddressSelectedRule}
          required
        >
          <AddressInput
            disabled={!canManage}
            onSelectCallback={updateAddressFields}
          />
        </Form.Item>

        <AddressForm
          addressLineBlurCallback={() => form.validateFields()}
          disabled={!canManage}
        />

        <Form.Item label='Contact number'>
          <Input.Group>
            <Row gutter={8}>
              <Col span={5}>
                <Input defaultValue='+44' disabled />
              </Col>
              <Col span={19}>
                <Form.Item
                  name='contactNumber'
                  noStyle
                  rules={[UKPhoneValidatorRule]}
                >
                  <Input
                    placeholder='7911 123456'
                    disabled={!canManage}
                    onFocus={onFocusScrollNumber}
                    onBlur={onBlurScrollNumber}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name='slug'
          label='Location URL'
          rules={[
            {
              required: true,
              message: 'Location URL is required'
            },
            {
              validator: (_: any, val: string) => {
                if (val.match('[^\\w-]+')) {
                  return Promise.reject(
                    'Special characters are not allowed except hyphen(-)'
                  )
                }
                return Promise.resolve()
              }
            },
            UniqueSlugValidatorRule(client, merchant.id, store.slug)
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='courierInstructions'
          label={
            <>
              Courier instructions{' '}
              <Tooltip
                title={
                  <a
                    href='https://support.slerp.com/knowledge/customers-and-guests-1'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    We recommend adding details here to help and manage
                    couriers.
                    <br />
                    <br />
                    i.e. Please wait outside for the order.
                  </a>
                }
              >
                <QuestionCircleOutlined className='_ml-8 _mr-8' />
              </Tooltip>
            </>
          }
        >
          <Input.TextArea rows={4} disabled={!canManage} />
        </Form.Item>

        <Form.Item
          data-testid='store-archiving-switch'
          name='is_archived'
          label='Archive your store'
          valuePropName='checked'
        >
          <Switch disabled={!canManage} />
        </Form.Item>

        <Form.Item>
          <Button
            title='Save'
            htmlType='submit'
            className='_center-vertical _ml-auto'
            disabled={!canManage}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
export default StoreDetails
